import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { rootPage as useRootStyles, cardsBody as useStyles } from "../../shared/sharedStyles";
import { Title1 } from "@fluentui/react-components";
import { Eye20Regular } from "@fluentui/react-icons";
import { Card } from "../../../library/src";
import SiteCardBody from "./SiteCardBody";
const SitesCards = ({ sites, openDetailsView }) => {
    const styles = useStyles();
    const rootStyles = useRootStyles();
    const onClickOpenDetailsView = (site) => {
        openDetailsView(site);
    };
    const menuCard = (site) => {
        return [
            {
                key: "site-menu-1",
                name: "Details",
                icon: _jsx(Eye20Regular, {}),
                onClick: () => {
                    onClickOpenDetailsView(site);
                },
            },
        ];
    };
    return (_jsxs("div", { className: rootStyles.wrapper, children: [_jsx(Title1, { className: rootStyles.pageTitle, children: "Sites" }), _jsx("div", { className: styles.container, children: sites.map((site) => (_jsx("div", { className: styles.cardContainer, children: _jsx(Card, { headerText: site.name, headerBadge: true, onClickHeader: () => {
                            onClickOpenDetailsView(site);
                        }, menuItems: menuCard(site), children: _jsx(SiteCardBody, { site: site }) }) }, site.id))) })] }));
};
export default SitesCards;
