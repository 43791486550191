import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from "@apollo/client";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { rootPage as useStyles } from "../../shared/sharedStyles";
import { SystemKind } from "../../../types";
import { Spinner } from "@fluentui/react-components";
import { ErrorDisplay, ErrorMessages } from "../../Error";
import SitesCards from "./SitesCards";
import { Details } from "../../../library/src";
import { SiteDetail } from "./Details";
const query = gql `
  query getSites($tenantIdOrKey: String!) {
    tenant(tenantIdOrKey: $tenantIdOrKey) {
      id
      key
      sites {
        description
        id
        name
        systemLinks {
          alias
          id
          systemId
          systemName
        }
      }
      systems {
        id
        type
      }
    }
  }
`;
const nullSite = {
    description: "",
    id: "",
    name: "",
    systemLinks: [],
};
const Sites = () => {
    const styles = useStyles();
    const { tenantIdOrKey } = useParams();
    const [openDetailsView, setOpenDetailsView] = useState(false);
    const [siteToShowInDetailsView, setSiteToShowInDetailsView] = useState(nullSite);
    const openDetailsViewAndSetItem = (systems) => {
        return (site) => {
            const extendedSystemLinks = site.systemLinks.map((systemLink) => {
                var _a;
                const system = systems.find((sys) => sys.id === systemLink.systemId);
                return { ...systemLink, systemType: (_a = system === null || system === void 0 ? void 0 : system.type) !== null && _a !== void 0 ? _a : SystemKind.NULL };
            });
            setOpenDetailsView(true);
            setSiteToShowInDetailsView({ ...site, systemLinks: extendedSystemLinks });
        };
    };
    const { loading, error, data } = useQuery(query, {
        variables: { tenantIdOrKey: tenantIdOrKey },
    });
    let content;
    if (loading) {
        content = _jsx(Spinner, { label: "Loading...", size: "huge", className: styles.spinner });
    }
    else if (error) {
        content = _jsx(ErrorDisplay, { error: { message: error === null || error === void 0 ? void 0 : error.message } });
    }
    else if (data.tenant === null) {
        content = _jsx(ErrorDisplay, { error: { message: ErrorMessages.invalidTenant } });
    }
    else {
        content = _jsx(SitesCards, { sites: data.tenant.sites, openDetailsView: openDetailsViewAndSetItem(data.tenant.systems) });
    }
    return (_jsxs("div", { className: styles.root, children: [_jsx(Details, { isOpen: openDetailsView, onDismiss: () => setOpenDetailsView(false), title: `Details ${siteToShowInDetailsView.name}`, children: _jsx(Details.Body, { children: _jsx(SiteDetail, { site: siteToShowInDetailsView }) }) }), _jsx("div", { className: styles.body, children: content })] }));
};
export default Sites;
