import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useRef } from "react";
import { Badge, Card as CardFluent, CardHeader as CardHeaderFluent, makeStyles, mergeClasses, tokens, } from "@fluentui/react-components";
import CardHeader from "./CardHeader";
const useStyles = makeStyles({
    card: {
        display: "flex",
        maxWidth: "100%",
        height: "100%",
    },
    cardWarning: {
        backgroundColor: tokens.colorPaletteRedBackground1,
    },
    header: {
        display: "inline",
    },
    body: {
        overflowX: "auto",
        overflowY: "auto",
        flexGrow: "1",
        height: "100%",
    },
    badge: {
        backgroundColor: tokens.colorBrandBackground,
        position: "absolute",
        bottom: "10px",
        right: "10px",
    },
});
const Card = (props) => {
    const { children, headerText, headerBadge, menuItems, onClickHeader, activeLinkHeader = true, warning, warningText, } = props;
    const styles = useStyles();
    const [cardHeader, setCardHeader] = useState(_jsx(_Fragment, {}));
    const cardRef = useRef(null);
    useEffect(() => {
        if (cardRef.current) {
            setCardHeader(_jsx(CardHeader, { headerText: headerText, headerBadge: headerBadge, menuItems: menuItems, onClickHeader: onClickHeader, warning: warning, mountModule: cardRef.current, activeLinkHeader: activeLinkHeader }));
        }
    }, [activeLinkHeader]);
    return (_jsxs(CardFluent, { ref: cardRef, className: mergeClasses(warning && styles.cardWarning, styles.card), children: [_jsx(CardHeaderFluent, { className: styles.header, header: cardHeader }), _jsx("div", { className: styles.body, children: children }), warningText && (_jsx(Badge, { "data-testid": "card-warning-text", shape: "rounded", className: styles.badge, children: warningText }))] }));
};
export default Card;
