import { ApolloClient, ApolloLink } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createHttpLink } from "@apollo/client/link/http";
import { RetryLink } from "@apollo/client/link/retry";
import persistCache from "apollo3-cache-persist/lib/persistCache";
import { acquireApiToken } from "../Authentication/authAPI";
import { apiConfig } from "../Authentication/authConfig";
import { fetchRetryAttemptCount, fetchRetryMaxDelay, fetchRetryMinDelay } from "./api";
// Create an error link for handling errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
    }
    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
    }
});
// Create a RetryLink instance with custom configuration
const retryLink = new RetryLink({
    delay: {
        initial: fetchRetryMinDelay,
        max: fetchRetryMaxDelay,
        jitter: true,
    },
    attempts: {
        max: fetchRetryAttemptCount,
        retryIf: (error, _operation) => {
            return error.statusCode >= 499;
        },
    },
});
const authLink = setContext(async (_, { headers }) => {
    const request = await acquireApiToken();
    const apolloToken = `Bearer ${request.accessToken}`;
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: apolloToken,
            accept: "application/graphql-response+json",
        },
    };
});
// Create an HTTP link
const httpLink = createHttpLink({
    uri: `${apiConfig.apiEndPoint}/${apiConfig.graphQL}`,
});
// Combine the links in the correct order: errorLink, retryLink, authLink, httpLink
const link = ApolloLink.from([errorLink, retryLink, authLink, httpLink]);
// Create an in memory cache
const cache = new InMemoryCache({
    typePolicies: {
        TenantPrincipalInformation: {
            fields: {
                roleAssignments: {
                    merge: false,
                },
            },
        },
    },
});
// Await before instantiating ApolloClient, else queries might run before the cache is persisted
await persistCache({
    cache,
    storage: window.localStorage,
});
// Create the ApolloClient instance
export const apolloClient = new ApolloClient({
    link,
    cache,
    defaultOptions: {
        watchQuery: { fetchPolicy: "cache-and-network" },
        mutate: { errorPolicy: "all" },
    },
});
