import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Body1, Spinner, makeStyles, mergeClasses, tokens, Toast, Toaster, ToastTitle, ToastTrigger, useId, useToastController, Link, ToastBody, Caption2Strong, Caption2, } from "@fluentui/react-components";
import { bundleIcon, Play20Regular, Play20Filled, iconFilledClassName, iconRegularClassName, } from "@fluentui/react-icons";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { EXECUTE_JOB } from "../../api/api";
import { setSelectedJob } from "../../redux/jobsSlice";
const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "row",
    },
    runJob: {
        minWidth: "3.25rem",
        display: "flex",
        columnGap: "0.4rem",
        marginLeft: "0.7rem",
        cursor: "pointer",
        alignItems: "center",
        ":hover": {
            [`& .${iconFilledClassName}`]: {
                display: "inline",
            },
            [`& .${iconRegularClassName}`]: {
                display: "none",
            },
        },
    },
    disabled: {
        cursor: "not-allowed",
        color: tokens.colorNeutralForegroundDisabled,
    },
    errorToastMessage: {
        maxHeight: "6.25rem",
        overflowY: "auto",
    },
    noWrap: {
        whiteSpace: "nowrap",
    },
});
const RunIcon = bundleIcon(Play20Filled, Play20Regular);
const RunJobButton = () => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const toasterID = useId("runJobToaster");
    const { dispatchToast } = useToastController(toasterID);
    const { tenantIdOrKey } = useParams();
    const selectedSite = useSelector((state) => state.appState.selectedSite);
    const selectedJob = useSelector((state) => state.jobs.selectedJob);
    const [executeJob, { loading, error, data }] = useMutation(EXECUTE_JOB, {
        variables: { tenantIdOrKey: tenantIdOrKey, siteIdOrName: selectedSite.id, jobDefinitionIdOrName: selectedJob },
    });
    useEffect(() => {
        dispatch(setSelectedJob(""));
    }, [dispatch]);
    useEffect(() => {
        if (data) {
            dispatchToast(_jsxs(Toast, { children: [_jsx(ToastTitle, { action: _jsx(ToastTrigger, { children: _jsx(Link, { children: "Dismiss" }) }), children: `Job "${selectedJob}" started successfully` }), _jsxs(ToastBody, { "data-testid": "run-job-success-message", children: [_jsx("div", { children: _jsx(Caption2Strong, { children: "Execution Id:" }) }), _jsx("div", { children: _jsx(Caption2, { className: styles.noWrap, children: data.executeJob.jobExecutionId }) })] })] }), { timeout: -1, intent: "success" });
        }
        if (error) {
            dispatchToast(_jsxs(Toast, { children: [_jsx(ToastTitle, { action: _jsx(ToastTrigger, { children: _jsx(Link, { children: "Dismiss" }) }), children: `Job "${selectedJob}" run error` }), _jsx(ToastBody, { children: _jsx("div", { className: styles.errorToastMessage, children: _jsx(Caption2, { children: error.message }) }) })] }), { timeout: -1, intent: "error" });
        }
    }, [error, data, dispatchToast, dispatch]);
    const onClickRunJob = () => {
        if (!loading && tenantIdOrKey && selectedJob) {
            executeJob();
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { role: "button", className: mergeClasses(styles.runJob, !selectedJob && styles.disabled), onClick: onClickRunJob, "data-testid": "run-button", children: [loading ? _jsx(Spinner, { size: "extra-tiny" }) : _jsx(RunIcon, {}), " ", _jsx(Body1, { children: `Run ${selectedJob !== null && selectedJob !== void 0 ? selectedJob : ""}` })] }), _jsx(Toaster, { toasterId: toasterID, position: "bottom-end" })] }));
};
export default RunJobButton;
