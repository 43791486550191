import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMsal } from "@azure/msal-react";
import { makeStyles, tokens } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { navigateToSettingsPages, setError, setIsTenantSettingsPageActive } from "../../redux/appStateSlice";
import { resetUserInfo, setUserInfo } from "../../redux/userInfoSlice";
import { getMeInfo } from "../../api/api";
import { ContainerHeaderHeight } from "../shared/sharedStyles";
import HeaderBarSettings from "./HeaderBarSettings";
import PopoverMenu from "./PopoverMenu";
import TenantName from "./TenantName";
import SettingsIcon from "./SettingsIcon";
const useStyles = makeStyles({
    header: {
        backgroundColor: tokens.colorBrandBackground,
        display: "flex",
        height: ContainerHeaderHeight,
        flexShrink: 0,
        justifyContent: "space-between",
        alignItems: "center",
    },
    headerSettings: {
        display: "flex",
        flexBasis: ContainerHeaderHeight,
        flexShrink: 0,
    },
    headerLeft: {
        display: "flex",
        alignItems: "center",
        paddingLeft: "0.75rem",
    },
    headerRight: {
        display: "flex",
        alignItems: "center",
    },
});
const HeaderBar = () => {
    var _a;
    const styles = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [name, setName] = useState("");
    const [userName, setUserName] = useState("");
    const tenant = useSelector((state) => state.tenant);
    const { isTenantSettingsPageActive, settingsPageToNavigate } = useSelector((state) => state.appState);
    useEffect(() => {
        const fetchMeData = async () => {
            if (tenant === null || tenant === void 0 ? void 0 : tenant.id) {
                try {
                    const meData = await getMeInfo(tenant.id);
                    dispatch(setUserInfo(meData.data.me));
                }
                catch (error) {
                    console.error("Error fetching user info in the HeaderBar component: ", error);
                    dispatch(setError());
                    dispatch(resetUserInfo());
                }
            }
        };
        fetchMeData();
    }, [tenant]);
    useEffect(() => {
        if (settingsPageToNavigate) {
            navigate(settingsPageToNavigate);
            dispatch(navigateToSettingsPages(""));
        }
    }, [settingsPageToNavigate, history, dispatch]);
    useEffect(() => {
        var _a;
        if (activeAccount) {
            setName((_a = activeAccount.name) !== null && _a !== void 0 ? _a : "");
            setUserName(activeAccount.username);
        }
        else {
            setName("");
            setUserName("");
        }
    }, [activeAccount]);
    useEffect(() => {
        if (location.pathname.indexOf("settings") !== -1) {
            dispatch(setIsTenantSettingsPageActive(true));
        }
        else {
            dispatch(setIsTenantSettingsPageActive(false));
        }
    }, [location, dispatch]);
    return (_jsxs("header", { children: [_jsxs("div", { "data-testid": "header-bar", className: styles.header, children: [_jsx("div", { className: styles.headerLeft, children: _jsx(TenantName, { tenantName: isTenantSettingsPageActive ? "Tenant - Settings" : (_a = tenant === null || tenant === void 0 ? void 0 : tenant.name) !== null && _a !== void 0 ? _a : "" }) }), _jsxs("div", { className: styles.headerRight, children: [_jsx(SettingsIcon, {}), !isTenantSettingsPageActive && _jsx(PopoverMenu, { name: name, userName: userName })] })] }), isTenantSettingsPageActive && _jsx(HeaderBarSettings, {})] }));
};
export default HeaderBar;
