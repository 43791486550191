import { createSlice } from "@reduxjs/toolkit";
export const initialState = {
    id: "",
    key: "",
    name: "",
    azureTenants: [],
    sites: [],
};
const tenant = createSlice({
    name: "tenant",
    initialState,
    reducers: {
        setTenant(state, action) {
            state.id = action.payload.id;
            state.key = action.payload.key;
            state.name = action.payload.name;
            state.azureTenants = action.payload.azureTenants;
            state.sites = action.payload.sites;
        },
        resetTenant(state) {
            state.id = initialState.id;
            state.key = initialState.key;
            state.name = initialState.name;
            state.azureTenants = initialState.azureTenants;
            state.sites = initialState.sites;
        },
    },
});
export const { setTenant, resetTenant } = tenant.actions;
export default tenant.reducer;
