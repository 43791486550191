import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, makeStyles, shorthands, tokens, } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";
var ModalTypeEnum;
(function (ModalTypeEnum) {
    ModalTypeEnum[ModalTypeEnum["Normal"] = 0] = "Normal";
    ModalTypeEnum[ModalTypeEnum["Info"] = 1] = "Info";
    ModalTypeEnum[ModalTypeEnum["Warning"] = 2] = "Warning";
    ModalTypeEnum[ModalTypeEnum["Error"] = 3] = "Error";
})(ModalTypeEnum || (ModalTypeEnum = {}));
var ModalButtonTypeEnum;
(function (ModalButtonTypeEnum) {
    ModalButtonTypeEnum["Primary"] = "primary";
    ModalButtonTypeEnum["Secondary"] = "secondary";
})(ModalButtonTypeEnum || (ModalButtonTypeEnum = {}));
const useStyles = makeStyles({
    root: {
        position: "fixed",
        backgroundColor: tokens.colorNeutralForeground4,
        opacity: "0.6",
        zIndex: 99999,
        ...shorthands.inset(0),
    },
});
const Modal = (props) => {
    const { isOpen, onDismiss, title, type = ModalTypeEnum.Normal, bodyText, buttons, children } = props;
    const styles = useStyles();
    if (bodyText && children) {
        throw new Error("Cannot use both bodyText and children at the same time");
    }
    const modal = (_jsx("div", { "data-testid": "modal-dialog", className: styles.root, children: _jsx(Dialog, { modalType: "non-modal", open: true, children: _jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(DialogTitle, { action: _jsx(DialogTrigger, { action: "close", children: _jsx(Button, { appearance: "subtle", "aria-label": "close", "data-testid": "modal-close-button", icon: _jsx(Dismiss24Regular, {}), onClick: () => onDismiss() }) }), children: title }), _jsx(DialogContent, { children: _jsx(ModalBody, { modalType: type, bodyText: bodyText, children: children }) }), buttons && _jsx(ModalFooter, { buttons: buttons })] }) }) }) }));
    return isOpen ? modal : _jsx(_Fragment, {});
};
export default Modal;
export { ModalButtonTypeEnum, ModalTypeEnum };
