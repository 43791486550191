import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Textbox20Regular, Connected20Regular } from "@fluentui/react-icons";
import { Text, Tooltip } from "@fluentui/react-components";
import { makeStyles, tokens, mergeClasses } from "@fluentui/react-components";
import { cardsBody } from "../../shared/sharedStyles";
import { noDescription } from "./sitesUtils";
import useElementResize from "../../../library/src/hooks/useElementResize";
const useStyles = makeStyles({
    twoLineText: {
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflowY: "hidden",
        textOverflow: "ellipsis",
        height: "2.25rem",
    },
    noDescription: {
        color: tokens.colorNeutralStrokeDisabled,
    },
});
const SiteCardBody = ({ site }) => {
    const cardBodyStyles = cardsBody();
    const styles = useStyles();
    const { ref: textReference, overflow } = useElementResize();
    const description = site.description ? site.description : noDescription;
    const systemLinksText = (systemLinksLength) => `${systemLinksLength} ${systemLinksLength === 1 ? "System Link" : "System Links"}`;
    const descriptionElement = (_jsx("div", { ref: textReference, className: mergeClasses(styles.twoLineText, !site.description && styles.noDescription), children: description }));
    return (_jsx("div", { className: cardBodyStyles.cardBody, children: _jsxs("div", { className: cardBodyStyles.cardBodyItem, children: [_jsxs("div", { className: cardBodyStyles.line, children: [_jsx("div", { children: _jsx(Textbox20Regular, {}) }), overflow ? (_jsx(Tooltip, { content: description, relationship: "description", positioning: "after", withArrow: true, children: descriptionElement })) : (descriptionElement)] }), _jsxs("div", { className: cardBodyStyles.line, children: [_jsx(Connected20Regular, {}), _jsx(Text, { className: cardBodyStyles.text, children: systemLinksText(site.systemLinks.length) })] })] }) }));
};
export default SiteCardBody;
