import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Subtitle2Stronger, Text } from "@fluentui/react-components";
import { details as useStyles } from "../../../shared/sharedStyles";
import { noDescription } from "../sitesUtils";
const Description = ({ description }) => {
    const styles = useStyles();
    const siteDescription = description !== null && description !== void 0 ? description : noDescription;
    return (_jsxs("div", { className: styles.section, "data-testid": "site-details-description", children: [_jsx(Subtitle2Stronger, { className: styles.sectionTitle, children: "Description" }), _jsx("div", { className: styles.row, children: _jsx("div", { children: _jsx(Text, { children: siteDescription }) }) })] }));
};
export default Description;
