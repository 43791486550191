import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Nav } from "../../library/src";
import { Connected16Filled, Connected16Regular, Home24Filled, Home24Regular, Navigation24Regular, People20Regular, People20Filled, Server24Regular, Server24Filled, ServerMultiple20Regular, ServerMultiple20Filled, TasksApp24Filled, TasksApp24Regular, Library24Filled, Library24Regular, } from "@fluentui/react-icons";
import { Divider, makeStyles, mergeClasses, shorthands, tokens } from "@fluentui/react-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setBurgerIcon, setComeBackLocationPath, toggleBurgerIcon } from "../../redux/appStateSlice";
import { useResize } from "../../hooks";
const getTenantFromUrl = () => {
    const pathname = window.location.pathname;
    return pathname.length > 1 ? pathname.split("/")[1] : "";
};
const getGroups = () => {
    let tenantIdOrKey = getTenantFromUrl();
    if (!tenantIdOrKey)
        return [];
    const groups = [
        {
            key: `/${tenantIdOrKey}`,
            name: "Home",
            icon: Home24Regular,
            iconActive: Home24Filled,
            url: `/${tenantIdOrKey}`,
        },
        {
            key: `/${tenantIdOrKey}/jobs`,
            name: "Jobs",
            icon: TasksApp24Regular,
            iconActive: TasksApp24Filled,
            url: `/${tenantIdOrKey}/jobs`,
        },
        {
            key: `/${tenantIdOrKey}/system-links`,
            name: "System Links",
            icon: Connected16Regular,
            iconActive: Connected16Filled,
            url: `/${tenantIdOrKey}/system-links`,
        },
    ];
    return groups;
};
const getGroupsTenantSettings = () => {
    let tenantIdOrKey = getTenantFromUrl();
    if (!tenantIdOrKey)
        return [];
    const groups = [
        {
            key: `/${tenantIdOrKey}/settings/systems`,
            name: "Systems",
            icon: Server24Regular,
            iconActive: Server24Filled,
            url: `/${tenantIdOrKey}/settings/systems`,
        },
        {
            key: `/${tenantIdOrKey}/settings/gateways`,
            name: "Gateways",
            icon: ServerMultiple20Regular,
            iconActive: ServerMultiple20Filled,
            url: `/${tenantIdOrKey}/settings/gateways`,
        },
        {
            key: `/${tenantIdOrKey}/settings/sites`,
            name: "Sites",
            icon: Library24Regular,
            iconActive: Library24Filled,
            url: `/${tenantIdOrKey}/settings/sites`,
        },
        {
            key: `/${tenantIdOrKey}/settings/users`,
            name: "Users & Roles",
            icon: People20Regular,
            iconActive: People20Filled,
            url: `/${tenantIdOrKey}/settings/users`,
        },
    ];
    return groups;
};
const useStyles = makeStyles({
    root: {
        display: "flex",
        flexShrink: 0,
        flexDirection: "column",
        position: "relative",
        ...shorthands.transition("flex-basis", "0.3s", tokens.curveEasyEase),
    },
    rootTablet: {
        position: "fixed",
        height: "100%",
        backgroundColor: tokens.colorNeutralBackground2,
        zIndex: 9999,
        ...shorthands.transition("flex-basis", "0.3s", tokens.curveEasyEase),
    },
    hamburger: {
        height: "2.875rem",
        fontSize: "1.125rem",
        position: "relative",
        userSelect: "none",
    },
    fold: {
        paddingRight: "1em",
        flexBasis: "14.375rem",
    },
    unfold: {
        paddingRight: 0,
        flexBasis: "3.375rem",
    },
    navigationIcon: {
        position: "absolute",
        cursor: "pointer",
        top: "25%",
        left: "calc(1em - 0.125rem)",
    },
    divider: {
        height: "100%",
        position: "absolute",
        top: "0",
        right: "0",
    },
    menuSpace: {
        width: "3.375rem",
        height: "100%",
        flexShrink: 0,
    },
    smallDeviceWrapper: {
        ...shorthands.transition("width", "0.3s", tokens.curveEasyEase),
    },
    smallDeviceWrapperUnfolded: {
        width: "3.375rem",
    },
    smallDeviceWrapperFolded: {
        width: "14.375rem",
    },
});
const NavPane = () => {
    const dispatch = useDispatch();
    const { burgerIconIsActive, isTenantSettingsPageActive } = useSelector((state) => state.appState);
    const [smallDevice, setSmallDevice] = useState();
    const toggleMenu = () => {
        dispatch(toggleBurgerIcon());
    };
    const windowsSize = useResize();
    const styles = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const onClickMenuItem = (item) => {
        if (item) {
            if (smallDevice) {
                dispatch(setBurgerIcon(true));
            }
            navigate(item.url);
        }
    };
    useEffect(() => {
        if (location.pathname.indexOf("settings") === -1) {
            dispatch(setComeBackLocationPath(location.pathname));
        }
    }, [dispatch, location]);
    useEffect(() => {
        if (windowsSize.width <= 992) {
            dispatch(setBurgerIcon(true));
            setSmallDevice(true);
        }
        else {
            dispatch(setBurgerIcon(false));
            setSmallDevice(false);
        }
    }, [dispatch, windowsSize]);
    return (_jsxs(_Fragment, { children: [smallDevice && _jsx("div", { className: styles.menuSpace }), _jsxs("div", { className: mergeClasses(styles.root, burgerIconIsActive ? styles.unfold : styles.fold, smallDevice && styles.rootTablet), "data-testid": isTenantSettingsPageActive ? "nav-pane-settings" : "nav-pane", children: [_jsx("div", { className: styles.hamburger, "data-testid": "burger", children: _jsx(Navigation24Regular, { className: styles.navigationIcon, onClick: toggleMenu }) }), _jsx("div", { className: mergeClasses(smallDevice && styles.smallDeviceWrapper, smallDevice && (burgerIconIsActive ? styles.smallDeviceWrapperUnfolded : styles.smallDeviceWrapperFolded)), "data-testid": "menu-wrapper", children: _jsx(Nav, { groups: isTenantSettingsPageActive ? getGroupsTenantSettings() : getGroups(), selectedKey: window.location.pathname, onLinkClick: onClickMenuItem, onlyIcons: burgerIconIsActive }) }), !burgerIconIsActive && _jsx(Divider, { vertical: true, className: styles.divider })] })] }));
};
export default NavPane;
