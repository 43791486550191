import { jsx as _jsx } from "react/jsx-runtime";
import { Badge, makeStyles } from "@fluentui/react-components";
const userRolesDictionary = {
    1: "Tenant Admin",
    2: "Site Admin",
    3: "Job Designer",
    4: "Consumer",
};
const useStyles = makeStyles({
    badge: {
        paddingRight: "0.3rem",
    },
});
const Role = ({ roleId }) => {
    const styles = useStyles();
    return (_jsx("div", { className: styles.badge, children: _jsx(Badge, { "data-testid": "role-cell-item-badge", shape: "rounded", color: "informative", size: "extra-large", children: userRolesDictionary[roleId] }) }));
};
export default Role;
