import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { DataGrid, DataGridBody, DataGridCell, DataGridHeaderCell, DataGridHeaderRow, } from "@fluentui-contrib/react-data-grid-react-window-grid";
import { DataGridHeader, useFluent, useScrollbarWidth, } from "@fluentui/react-components";
import { createPersonaColumn, createRoleColumn } from "./components";
const cellHeight = 45;
const cellWidth = 300;
const cellRenderer = ({ item }, column, style) => (_jsx(DataGridCell, { style: { ...style, boxSizing: "border-box" }, children: column.renderCell(item) }));
const PrincipalList = ({ principals, sites, selectedSite, parentRef, }) => {
    const { targetDocument } = useFluent();
    const scrollbarWidth = useScrollbarWidth({ targetDocument });
    const [parentOffset, setParentOffset] = useState({
        width: 0,
        height: 0,
    });
    const [sortState, setSortState] = useState({
        sortColumn: "name",
        sortDirection: "ascending",
    });
    const columns = [createPersonaColumn("displayName", "Principal", true)];
    const onSortChange = (_e, nextSortState) => {
        setSortState(nextSortState);
    };
    useEffect(() => {
        const handleResize = () => {
            if (parentRef.current) {
                setParentOffset({
                    width: parentRef.current.offsetWidth - 15 - (scrollbarWidth !== null && scrollbarWidth !== void 0 ? scrollbarWidth : 0),
                    height: parentRef.current.offsetHeight - 50,
                });
            }
        };
        handleResize();
        const resizeObserver = new ResizeObserver(() => {
            handleResize();
        });
        if (parentRef.current) {
            resizeObserver.observe(parentRef.current);
        }
        // Clean up
        return () => {
            if (parentRef.current) {
                resizeObserver.unobserve(parentRef.current);
            }
            resizeObserver.disconnect();
        };
    }, []);
    if (selectedSite) {
        columns.push(createRoleColumn("roleAssignments", "Roles", selectedSite.id));
    }
    else {
        sites.forEach((site) => {
            columns.push(createRoleColumn("roleAssignments", site.name, site.id, true));
        });
    }
    columns.push(createRoleColumn("roleAssignments", "Tenant", null));
    return (_jsxs(DataGrid
    // To ensure that DataGrid recalculates the column widths, we use the parent
    // width as a key. This forces a re-render of DataGrid when the parent width changes.
    , { 
        // To ensure that DataGrid recalculates the column widths, we use the parent
        // width as a key. This forces a re-render of DataGrid when the parent width changes.
        noNativeElements: true, items: principals, columns: columns, size: "medium", sortable: true, sortState: sortState, onSortChange: onSortChange, children: [_jsx(DataGridHeader, { children: _jsx(DataGridHeaderRow, { itemSize: () => cellWidth, height: cellHeight, width: parentOffset.width, children: ({ renderHeaderCell }, style) => (_jsx(DataGridHeaderCell, { as: "div", style: style, children: renderHeaderCell() })) }) }), _jsx(DataGridBody, { rowHeight: () => cellHeight, height: parentOffset.height, width: parentOffset.width, columnWidth: () => cellWidth, children: cellRenderer })] }, parentOffset.width));
};
export default PrincipalList;
